<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_105_24)">
      <path
        d="M248 171.233C233.219 177.401 216.997 180.807 199.98 180.807C131.062 180.807 75.193 124.938 75.193 56.0199C75.193 39.0028 78.5993 22.7813 84.7672 8C39.6852 26.8116 8 71.312 8 123.213C8 192.131 63.8691 248 132.787 248C184.688 248 229.188 216.315 248 171.233Z"
        stroke="currentColor"
        stroke-width="15"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_105_24">
        <rect
          width="256"
          height="256"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
